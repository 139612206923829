import { AMSV3Api, MAX_NUMBER_OF_RESULTS, clientAppApiKey } from '../helpers/openapi-axios-helper';
import {
  CreateClientRequest,
  CreateDeliveryDocumentRequest,
  CreateDeliveryRequest,
  CreateDeliveryRequestRequest,
  CreateInvoiceRequest,
  CreateItemDeliverySubscriptionRequest,
  CreateSupplierReserveSubscriptionRequest,
  CreateTransactionRequest,
  CreateTreasuryRequest,
  ItemDeliverySubscriptionResponse,
  PaginatedListResponseActOfAcceptanceListResponse,
  PaginatedListResponseCompanyListResponse,
  PaginatedListResponseGroupResponse,
  PaginatedListResponseItemDeliverySubscriptionResponse,
  PaginatedListResponseItemListResponse,
  PaginatedListResponsePartnerListResponse,
  PaginatedListResponsePaymentOptionResponse,
  PaginatedListResponseRoleListResponse,
  PaginatedListResponseTransactionListResponse,
  PaginatedListResponseTreasuryListResponse,
  PaginatedListResponseUserListResponse,
  PaginatedListResponseWarehouseListResponse,
  PatchItemDeliverySubscriptionRequest,
  PatchSupplierReserveSubscriptionRequest,
  PatchTreasuryRequest,
  SaveItemRequest,
  SaveLegalEntityRequest,
  SavePartnerItemConfigurationRequest,
  SavePartnerRequest,
  SaveSupplierRequest,
  SetBarsyConfigurationRequest,
  SetICashConfigurationRequest,
  SetMistralConfigurationRequest,
  SetNoConfigurationRequest,
  SetOrderingPolicyRequest,
  SetPricesRequest,
  SetRetailConfigurationRequest,
  StartStockReceivingProcessRequest,
  UpdateBatchesRequest,
  UpdateClientRequest,
  UpdateDeliveryDocumentRequest,
  UpdateDeliveryRequestRequest,
  VerifyQuantitiesRequest
} from './api-v3';
import {
  DeliveryDocumentType,
  OrderStatus,
  deliveryDocumentTypeAliasMap,
  documentTypeAliasMap
} from '.';
import { toEndOfDay, toStartOfDay } from '../helpers/date-helper';

import { AxiosResponse } from 'axios';

export class AMSV3Service {
  getCompanies = async (
    query?: string,
    showInactive?: boolean
  ): Promise<AxiosResponse<PaginatedListResponseCompanyListResponse>> => {
    const axios = await AMSV3Api();
    return await axios.readCompaniesCompaniesGet([], query, showInactive, 1, MAX_NUMBER_OF_RESULTS);
  };

  getWarehouses = async (
    query?: string,
    showInactive?: boolean
  ): Promise<AxiosResponse<PaginatedListResponseWarehouseListResponse>> => {
    const axios = await AMSV3Api();
    return await axios.readWarehousesWarehousesGet(
      [],
      showInactive,
      query,
      1,
      MAX_NUMBER_OF_RESULTS
    );
  };

  getCompany = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.readCompanyByIdCompaniesIdGet(id);
  };

  getWarehouse = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.readWarehouseByIdWarehousesIdGet(id);
  };

  getStockByDeliveries = async (
    companyId?: number,
    warehouseId?: number,
    itemId?: number,
    hideDepleted?: boolean,
    suppliers?: number[],
    itemCategories?: number[],
    manufacturerId?: number
  ) => {
    const axios = await AMSV3Api();
    return await axios.readStockByDeliveriesReportsStockByDeliveriesGet(
      companyId,
      warehouseId,
      itemId,
      manufacturerId,
      suppliers,
      itemCategories,
      hideDepleted,
      1,
      MAX_NUMBER_OF_RESULTS
    );
  };

  getDeliveryItems = async (
    fromDeliveryDate?: string,
    toDeliveryDate?: string,
    supplierId?: number,
    companies?: number[],
    warehouses?: number[],
    itemCategories?: number[],
    itemId?: number
  ) => {
    const axios = await AMSV3Api();
    return await axios.readDeliveryItemsReportsDeliveryItemsGet(
      fromDeliveryDate,
      toDeliveryDate,
      supplierId,
      companies,
      warehouses,
      itemCategories,
      itemId,
      1,
      MAX_NUMBER_OF_RESULTS
    );
  };

  getSales = async (
    fromDeliveryDate?: string,
    toDeliveryDate?: string,
    receivers?: number[],
    legalEntities?: number[],
    companies?: number[],
    warehouses?: number[],
    itemCategories?: number[],
    itemId?: number
  ) => {
    const axios = await AMSV3Api();
    return await axios.readSalesReportsSalesGet(
      fromDeliveryDate,
      toDeliveryDate,
      receivers,
      legalEntities,
      companies,
      warehouses,
      itemCategories,
      itemId,
      1,
      MAX_NUMBER_OF_RESULTS
    );
  };

  getPaymentOptions = async (): Promise<
    AxiosResponse<PaginatedListResponsePaymentOptionResponse>
  > => {
    const axios = await AMSV3Api();
    return await axios.readPaymentOptionsPaymentOptionsGet([], undefined, 1, MAX_NUMBER_OF_RESULTS);
  };

  getPaymentOption = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.readPaymentOptionByIdPaymentOptionsIdGet(id);
  };

  getPartners = async (
    query?: string,
    showInactive?: boolean
  ): Promise<AxiosResponse<PaginatedListResponsePartnerListResponse>> => {
    const axios = await AMSV3Api();
    return await axios.readPartnersPartnersGet([], showInactive, query, 1, MAX_NUMBER_OF_RESULTS);
  };

  getUsers = async (
    showInactive?: boolean,
    company?: number,
    query?: string
  ): Promise<AxiosResponse<PaginatedListResponseUserListResponse>> => {
    const axios = await AMSV3Api();
    return await axios.readUsersUsersGet(
      [],
      showInactive,
      company,
      query,
      1,
      MAX_NUMBER_OF_RESULTS
    );
  };

  getUser = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.readUserByIdUsersIdGet(id);
  };

  getGroups = async (): Promise<AxiosResponse<PaginatedListResponseGroupResponse>> => {
    const axios = await AMSV3Api();
    return await axios.readGroupsGroupsGet([], 1, MAX_NUMBER_OF_RESULTS);
  };

  getRoles = async (): Promise<AxiosResponse<PaginatedListResponseRoleListResponse>> => {
    const axios = await AMSV3Api();
    return await axios.readRolesRolesGet([], 1, MAX_NUMBER_OF_RESULTS);
  };

  getManufacturers = async (
    id?: number[]
  ): Promise<AxiosResponse<PaginatedListResponseRoleListResponse>> => {
    const axios = await AMSV3Api();
    return await axios.readManufacturersManufacturersGet(id, 1, MAX_NUMBER_OF_RESULTS);
  };

  getClients = async (
    id?: number[],
    showInactive?: boolean,
    partners?: number[],
    name?: string,
    email?: string
  ) => {
    const axios = await AMSV3Api();
    return await axios.readClientsClientsGet(
      id,
      showInactive,
      partners,
      name,
      email,
      1,
      MAX_NUMBER_OF_RESULTS
    );
  };

  createPartnerItemConfiguration = async (
    partnerId: number,
    request: SavePartnerItemConfigurationRequest
  ) => {
    const axios = await AMSV3Api();
    return await axios.createPartnerItemConfigurationPartnersPartnerIdItemConfigurationPost(
      partnerId,
      request
    );
  };

  getPartnerItemConfigurations = async (partnerId: number) => {
    const axios = await AMSV3Api();
    return await axios.readPartnerItemConfigurationsPartnersPartnerIdItemConfigurationGet(
      partnerId,
      1,
      MAX_NUMBER_OF_RESULTS
    );
  };

  getItemPartnerConfigurations = async (itemId: number) => {
    const axios = await AMSV3Api();
    return await axios.readPartnerItemConfigurationsItemsItemIdPartnerConfigurationsGet(
      itemId,
      1,
      MAX_NUMBER_OF_RESULTS
    );
  };

  getClient = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.readClientByIdClientsIdGet(id);
  };

  getActOfAcceptanceItems = async (actOfAcceptanceId: number) => {
    const axios = await AMSV3Api();
    return await axios.readActOfAcceptanceItemsActsOfAcceptanceActOfAcceptanceIdItemsGet(
      actOfAcceptanceId
    );
  };

  createClient = async (client: CreateClientRequest) => {
    const axios = await AMSV3Api();
    return await axios.createClientsPost(client);
  };

  updateClient = async (id: number, client: UpdateClientRequest) => {
    const axios = await AMSV3Api();
    return await axios.updateClientClientsIdPatch(id, client);
  };

  getClientFavorites = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.readClientFavoritesClientsIdFavoritesGet(id, 1, MAX_NUMBER_OF_RESULTS);
  };

  getClientDevices = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.readClientDevicesClientsIdDevicesGet(id, 1, MAX_NUMBER_OF_RESULTS);
  };

  getClientShoppingCarts = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.readClientShoppingCartsClientsIdShoppingCartsGet(
      id,
      1,
      MAX_NUMBER_OF_RESULTS
    );
  };

  deleteClient = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.deleteClientClientsIdDelete(id);
  };

  loadInfoByVat = async (vat: string) => {
    const axios = await AMSV3Api();
    return await axios.loadInfoLegalEntitiesLoadInfoPost(vat);
  };

  updateLegalEntity = async (id: number, legalEntity: SaveLegalEntityRequest) => {
    const axios = await AMSV3Api();
    return await axios.updateLegalEntityLegalEntitiesIdPut(id, legalEntity);
  };

  createLegalEntity = async (legalEntity: SaveLegalEntityRequest) => {
    const axios = await AMSV3Api();
    return await axios.createLegalEntitiesPost(legalEntity);
  };

  getLegalEntity = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.readLegalEntityByIdLegalEntitiesIdGet(id);
  };

  getLegalEntities = async (
    id?: number[],
    showInactive?: boolean,
    query?: string,
    company?: number
  ) => {
    const axios = await AMSV3Api();
    return await axios.readLegalEntitiesLegalEntitiesGet(
      id,
      showInactive,
      query,
      company,
      1,
      MAX_NUMBER_OF_RESULTS
    );
  };

  getOrders = async (
    statusKeys?: string[],
    companyIds?: number[],
    warehouseIds?: number[],
    receiverIds?: number[],
    legalEntitiyIds?: number[],
    fromDeliveryDate?: string,
    toDeliveryDate?: string,
    withExecutionPercentage: boolean = false
  ) => {
    const axios = await AMSV3Api();
    if (withExecutionPercentage) {
      statusKeys = [
        OrderStatus.DELAYED.key,
        OrderStatus.DELAYED_DUE_TO_SUPPLIER.key,
        OrderStatus.DELAYED_DUE_TO_WAREHOUSE_STOCK.key
      ];
      fromDeliveryDate = toStartOfDay(new Date());
      toDeliveryDate = toEndOfDay(new Date());
    }
    return await axios.getB2bOrdersOrdersB2bOrdersGet(
      [],
      statusKeys,
      companyIds,
      warehouseIds,
      receiverIds,
      legalEntitiyIds,
      fromDeliveryDate,
      toDeliveryDate,
      1,
      10000
    );
  };

  getOrderExecutionPercentage = async (orderId: number) => {
    const axios = await AMSV3Api();
    return await axios.getOrderExecutionPercentageOrdersOrderIdExecutionPercentageGet(orderId);
  };

  sendPasswordResetEmail = async (email: string) => {
    const rawResponse = await fetch(
      `https://www.googleapis.com/identitytoolkit/v3/relyingparty/getOobConfirmationCode?key=${clientAppApiKey}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify({ requestType: 'PASSWORD_RESET', email: email })
      }
    );
    return await rawResponse.json();
  };

  getItemDeliverySubscriptions = async (
    warehouses?: number[],
    items?: number[]
  ): Promise<AxiosResponse<PaginatedListResponseItemDeliverySubscriptionResponse>> => {
    const axios = await AMSV3Api();
    return await axios.getItemDeliverySubscriptionsItemDeliverySubscriptionsGet(
      warehouses,
      items,
      1,
      MAX_NUMBER_OF_RESULTS
    );
  };

  getTransactions = async (
    id?: number[],
    invoiceId?: number,
    deliveryInvoiceId?: number,
    treasuries?: number[],
    fromDate?: Date | null,
    toDate?: Date | null
  ): Promise<AxiosResponse<PaginatedListResponseTransactionListResponse>> => {
    const axios = await AMSV3Api();
    return await axios.readTransactionsTransactionsGet(
      id,
      invoiceId,
      deliveryInvoiceId,
      treasuries,
      fromDate ? toStartOfDay(fromDate) : undefined,
      toDate ? toEndOfDay(toDate) : undefined,
      1,
      MAX_NUMBER_OF_RESULTS
    );
  };

  createTransaction = async (transaction: CreateTransactionRequest) => {
    const axios = await AMSV3Api();
    return await axios.createTransactionTransactionsPost(transaction);
  };

  deleteTransaction = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.deleteTransactionTransactionsIdDelete(id);
  };

  getTreasuries = async (
    id?: number[],
    companyId?: number
  ): Promise<AxiosResponse<PaginatedListResponseTreasuryListResponse>> => {
    const axios = await AMSV3Api();
    return await axios.readTreasuriesTreasuriesGet(
      id,
      companyId,
      1,
      MAX_NUMBER_OF_RESULTS,
      'name',
      'ASC'
    );
  };

  createTreasury = async (treasury: CreateTreasuryRequest) => {
    const axios = await AMSV3Api();
    return await axios.createTreasuryTreasuriesPost(treasury);
  };

  updateTreasury = async (id: number, treasury: PatchTreasuryRequest) => {
    const axios = await AMSV3Api();
    return await axios.updateTreasuryTreasuriesIdPut(id, treasury);
  };

  getInvoiceTransactions = async (
    invoiceId: number
  ): Promise<AxiosResponse<PaginatedListResponseTransactionListResponse>> => {
    const axios = await AMSV3Api();
    return await axios.readInvoiceTransactionsInvoicesInvoiceIdTransactionsGet(
      invoiceId,
      1,
      MAX_NUMBER_OF_RESULTS
    );
  };

  getSuppliers = async (id?: number[], query?: string, showInactive?: boolean) => {
    const axios = await AMSV3Api();
    return await axios.readSuppliersSuppliersGet(id, query, showInactive, 1, MAX_NUMBER_OF_RESULTS);
  };

  getSupplier = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.readSupplierByIdSuppliersIdGet(id);
  };

  createSupplier = async (supplier: SaveSupplierRequest) => {
    const axios = await AMSV3Api();
    return await axios.createSupplierSuppliersPost(supplier);
  };

  updateSupplier = async (id: number, supplier: SaveSupplierRequest) => {
    const axios = await AMSV3Api();
    return await axios.updateSupplierSuppliersIdPut(id, supplier);
  };

  getItemDeliverySubscription = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.readItemDeliverySubscriptionByIdItemDeliverySubscriptionsIdGet(id);
  };

  deleteItemDeliverySubscription = async (
    id: number
  ): Promise<AxiosResponse<ItemDeliverySubscriptionResponse>> => {
    const axios = await AMSV3Api();
    return await axios.deleteItemDeliverySubscriptionItemDeliverySubscriptionsIdDelete(id);
  };

  updateItemDeliverySubscription = async (
    id: number,
    request: PatchItemDeliverySubscriptionRequest
  ) => {
    const axios = await AMSV3Api();
    return await axios.updateItemDeliverySubscriptionItemDeliverySubscriptionsIdPut(
      id,
      JSON.parse(JSON.stringify(request))
    );
  };

  createItemDeliverySubscription = async (subscription: CreateItemDeliverySubscriptionRequest) => {
    const axios = await AMSV3Api();
    return await axios.createItemDeliverySubscriptionItemDeliverySubscriptionsPost(subscription);
  };

  createItem = async (item: SaveItemRequest) => {
    const axios = await AMSV3Api();
    return await axios.createItemsPost(item);
  };

  updateItem = async (id: number, item: SaveItemRequest) => {
    const axios = await AMSV3Api();
    return await axios.updateItemItemsIdPut(id, item);
  };

  deleteLinkedItem = async (itemId: number, linkedItemId: number) => {
    const axios = await AMSV3Api();
    return await axios.deleteLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdDelete(
      itemId,
      linkedItemId
    );
  };

  getLinkedItems = async (itemId: number) => {
    const axios = await AMSV3Api();
    return await axios.readLinkedItemAssociationsForItemItemsItemIdLinkedItemAssociationGet(itemId);
  };

  addLinkedItem = async (itemId: number, linkedItemId: number) => {
    const axios = await AMSV3Api();
    return await axios.createLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdPost(
      itemId,
      linkedItemId
    );
  };

  addSimilarItem = async (itemId: number, similarItemId: number) => {
    const axios = await AMSV3Api();
    return await axios.createSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdPost(
      itemId,
      similarItemId
    );
  };

  getSimilarItems = async (itemId: number) => {
    const axios = await AMSV3Api();
    return await axios.readSimilarItemAssociationsForItemItemsItemIdSimilarItemAssociationGet(
      itemId
    );
  };

  deleteSimilarItem = async (itemId: number, similarItemId: number) => {
    const axios = await AMSV3Api();
    return await axios.deleteSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdDelete(
      itemId,
      similarItemId
    );
  };

  getItems = async (
    itemIds?: number[],
    query?: string,
    categories?: number[],
    suppliers?: number[],
    manufacturers?: number[],
    partner?: number,
    showInactive?: boolean,
    page?: number,
    size?: number
  ): Promise<AxiosResponse<PaginatedListResponseItemListResponse>> => {
    console.log('getItems', itemIds);
    const axios = await AMSV3Api();
    return await axios.readItemsItemsGet(
      itemIds,
      query?.split(' '),
      categories,
      suppliers,
      manufacturers,
      partner,
      showInactive,
      !!page ? page : 1,
      !!size ? size : MAX_NUMBER_OF_RESULTS
    );
  };

  getItem = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.readItemByIdItemsIdGet(id);
  };

  getItemActiveOrderItems = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.readActiveOrderItemsItemsIdActiveOrderItemsGet(id, 1, MAX_NUMBER_OF_RESULTS);
  };

  getItemLastDeliveryPrice = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.readLastDeliveryPriceItemsIdLastDeliveryPriceGet(id);
  };

  testBarsyConnection = async (partnerId: number) => {
    const axios = await AMSV3Api();
    return await axios.testBarsyConnectionPartnersIdTestBarsyConnectionPost(partnerId);
  };

  updatePartnerItemConfiguration = async (
    id: number,
    request: SavePartnerItemConfigurationRequest
  ) => {
    const axios = await AMSV3Api();
    return await axios.updatePartnerItemConfigurationPartnersItemConfigurationIdPut(id, request);
  };

  deletePartnerItemConfiguration = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.deletePartnerItemConfigurationPartnersItemConfigurationIdDelete(id);
  };

  copyPartnerItemConfigurations = async (partnerId: number, sourcePartnerId: number) => {
    const axios = await AMSV3Api();
    return await axios.copyPartnerItemConfigurationsPartnersPartnerIdItemConfigurationCopySourcePartnerIdPost(
      partnerId,
      sourcePartnerId
    );
  };

  getSupplierReserveSubscriptions = async (id?: number[], suppliers?: number[]) => {
    const axios = await AMSV3Api();
    return await axios.readSupplierReserveSubscriptionsSupplierReserveSubscriptionsGet(
      id,
      suppliers,
      undefined,
      1,
      MAX_NUMBER_OF_RESULTS
    );
  };

  getSupplierReserveSubscription = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.readSupplierReserveSubscriptionByIdSupplierReserveSubscriptionsIdGet(id);
  };

  createSupplierReserveSubscription = async (
    supplierReserveSubscription: CreateSupplierReserveSubscriptionRequest
  ) => {
    const axios = await AMSV3Api();
    return await axios.createSupplierReserveSubscriptionSupplierReserveSubscriptionsPost(
      supplierReserveSubscription
    );
  };

  updateSupplierReserveSubscription = async (
    id: number,
    patchSupplierReserveSubscriptionRequest: PatchSupplierReserveSubscriptionRequest
  ) => {
    const axios = await AMSV3Api();
    return await axios.updateSupplierReserveSubscriptionSupplierReserveSubscriptionsIdPatch(
      id,
      patchSupplierReserveSubscriptionRequest
    );
  };

  deleteSupplierReserveSubscription = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.deleteSupplierReserveSubscriptionSupplierReserveSubscriptionsIdDelete(id);
  };

  getTreasuryBankAccountAmount = async (treasuryId: number) => {
    const axios = await AMSV3Api();
    return await axios.readTreasuryBankAccountAmountTreasuriesTreasuryIdBankAccountAmountGet(
      treasuryId
    );
  };

  getTreasuryCashAmount = async (treasuryId: number) => {
    const axios = await AMSV3Api();
    return await axios.readTreasuryCashAmountTreasuriesTreasuryIdCashAmountGet(treasuryId);
  };

  getPartner = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.readPartnerByIdPartnersIdGet(id);
  };

  createPartner = async (partnerRequest: SavePartnerRequest) => {
    const axios = await AMSV3Api();
    return await axios.createPartnerPartnersPost(partnerRequest);
  };

  updatePartner = async (id: number, partnerRequest: SavePartnerRequest) => {
    const axios = await AMSV3Api();
    return await axios.updatePartnerPartnersIdPut(id, partnerRequest);
  };

  getInvoices = async (
    invoiceIds?: number[],
    fromDate?: string,
    toDate?: string,
    legalEntities?: number[],
    companies?: number[],
    hideCancelled?: boolean,
    hidePaidInFull?: boolean
  ) => {
    const axios = await AMSV3Api();
    return await axios.readInvoicesInvoicesGet(
      invoiceIds,
      fromDate,
      toDate,
      legalEntities,
      companies,
      hideCancelled,
      hidePaidInFull,
      1,
      MAX_NUMBER_OF_RESULTS
    );
  };

  getInvoice = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.readInvoiceByIdInvoicesIdGet(id);
  };

  getInvoiceOrders = async (invoiceId: number) => {
    const axios = await AMSV3Api();
    return await axios.readInvoiceOrdersInvoicesInvoiceIdOrdersGet(invoiceId);
  };

  getInvoiceCreditNotes = async (invoiceId: number) => {
    const axios = await AMSV3Api();
    return await axios.readCreditNotesInvoicesInvoiceIdCreditNotesGet(invoiceId);
  };

  createInvoice = async (orderIds: number[], paymentOptionId: number) => {
    const axios = await AMSV3Api();
    const createInvoiceRequest: CreateInvoiceRequest = { orderIds, paymentOptionId };
    return await axios.createInvoicesPost(createInvoiceRequest);
  };

  cancelInvoiceByInvoiceId = async (invoiceId: number) => {
    const axios = await AMSV3Api();
    return await axios.cancelInvoiceByInvoiceIdInvoicesInvoiceIdPatch(invoiceId);
  };

  getActsOfAcceptance = async (
    fromDate: Date | null,
    toDate: Date | null,
    receivers?: number[],
    legalEntities?: number[],
    companies?: number[],
    users?: number[],
    orderTypes?: string[]
  ): Promise<AxiosResponse<PaginatedListResponseActOfAcceptanceListResponse>> => {
    const axios = await AMSV3Api();
    return await axios.readActsOfAcceptanceActsOfAcceptanceGet(
      undefined,
      fromDate ? toStartOfDay(fromDate) : undefined,
      toDate ? toEndOfDay(toDate) : undefined,
      receivers,
      legalEntities,
      companies,
      users,
      orderTypes,
      1,
      MAX_NUMBER_OF_RESULTS
    );
  };

  getActOfAcceptance = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.readActOfAcceptanceActsOfAcceptanceActOfAcceptanceIdGet(id);
  };

  getDeliveries = async (
    suppliers?: number[],
    warehouses?: number[],
    fromDeliveryDate?: string,
    toDeliveryDate?: string
  ) => {
    const axios = await AMSV3Api();
    return await axios.getDeliveriesDeliveriesGet(
      suppliers,
      warehouses,
      fromDeliveryDate,
      toDeliveryDate,
      1,
      MAX_NUMBER_OF_RESULTS
    );
  };

  setPartnerIntegrationConfiguration = async (
    partnerId: number,
    request:
      | SetNoConfigurationRequest
      | SetBarsyConfigurationRequest
      | SetICashConfigurationRequest
      | SetRetailConfigurationRequest
      | SetMistralConfigurationRequest
  ) => {
    const axios = await AMSV3Api();
    return await axios.setPartnerIntegrationSettingsPartnersPartnerIdIntegrationSettingsPost(
      partnerId,
      JSON.parse(JSON.stringify(request))
    );
  };

  getDeliveryRequests = async (
    deliveryRequestIds?: number[],
    fromDate?: string,
    toDate?: string,
    fromDelivery?: string,
    toDelivery?: string,
    suppliers?: number[],
    warehouses?: number[]
  ) => {
    const axios = await AMSV3Api();
    return await axios.readDeliveryRequestsDeliveryRequestsGet(
      deliveryRequestIds,
      fromDate ? fromDate : undefined,
      toDate ? toDate : undefined,
      fromDelivery ? fromDelivery : undefined,
      toDelivery ? toDelivery : undefined,
      suppliers,
      warehouses,
      1,
      MAX_NUMBER_OF_RESULTS
    );
  };

  getDeliveryRequest = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.readDeliveryRequestByIdDeliveryRequestsIdGet(id);
  };

  getDeliveryRequestItems = async (deliveryRequestId: number) => {
    const axios = await AMSV3Api();
    return await axios.readDeliveryRequestItemsDeliveryRequestsIdItemsGet(
      deliveryRequestId,
      1,
      MAX_NUMBER_OF_RESULTS
    );
  };

  getDeliveryRequestExpectedTotal = async (deliveryRequestId: number) => {
    const axios = await AMSV3Api();
    return await axios.getDeliveryRequestExpectedTotalDeliveryRequestsIdExpectedTotalGet(
      deliveryRequestId
    );
  };

  createDeliveryRequest = async (request: CreateDeliveryRequestRequest, sendEmail: boolean) => {
    const axios = await AMSV3Api();
    return await axios.createDeliveryRequestDeliveryRequestsPost(request, sendEmail);
  };

  updateDeliveryRequest = async (
    id: number,
    updateDeliveryRequestRequest: UpdateDeliveryRequestRequest
  ) => {
    const axios = await AMSV3Api();
    return await axios.updateDeliveryRequestDeliveryRequestsIdPut(id, updateDeliveryRequestRequest);
  };

  getStockReceivingProcesses = async (
    stockReceivingIds?: number[],
    fromDate?: string,
    toDate?: string
  ) => {
    const axios = await AMSV3Api();
    return await axios.readStockReceivingProcessesStockReceivingProcessesGet(
      stockReceivingIds,
      fromDate,
      toDate,
      1,
      MAX_NUMBER_OF_RESULTS
    );
  };

  getStockReceivingProcess = async (deliveryRequestId: number) => {
    const axios = await AMSV3Api();
    return await axios.readStockReceivingProcessByIdStockReceivingProcessesIdGet(deliveryRequestId);
  };

  getStockReceivingProcessItems = async (deliveryRequestId: number) => {
    const axios = await AMSV3Api();
    const processResp =
      await axios.readDeliveryRequestStockReceivingProcessDeliveryRequestsIdProcessGet(
        deliveryRequestId
      );
    return await axios.readStockReceivingProcessItemsStockReceivingProcessesIdItemsGet(
      processResp.data.id,
      1,
      MAX_NUMBER_OF_RESULTS
    );
  };

  createStockReceivingProcess = async (
    deliveryRequestId: number,
    request: StartStockReceivingProcessRequest
  ) => {
    const axios = await AMSV3Api();
    return await axios.startStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPost(
      deliveryRequestId,
      request
    );
  };

  updateStockReceivingProcessUpdateBatches = async (
    deliveryRequestId: number,
    request: UpdateBatchesRequest
  ) => {
    const axios = await AMSV3Api();
    return await axios.updateStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPatch(
      deliveryRequestId,
      JSON.parse(JSON.stringify(request))
    );
  };

  getPartnerItems = async (partnerId: number, search?: string) => {
    const axios = await AMSV3Api();
    return await axios.readItemsByPartnerPartnersPartnerIdItemsGet(partnerId, search);
  };

  getOrderingPolicy = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.readOrderingPolicyPartnersIdOrderingPolicyGet(id);
  };

  setOrderingPolicy = async (id: number, orderPolicy: SetOrderingPolicyRequest) => {
    const axios = await AMSV3Api();
    return await axios.setOrderingPolicyPartnersIdOrderingPolicyPut(id, orderPolicy);
  };

  getAvailabilities = async (
    itemIds?: number[],
    warehouseIds?: number[],
    realTime: boolean = false,
    page: number = 1,
    size: number = 10000
  ) => {
    const axios = await AMSV3Api();
    return await axios.readAvailabilitiesAvailabilitiesGet(
      itemIds,
      warehouseIds,
      realTime,
      page,
      size
    );
  };

  getOrderDocumentTypeAlias = (id: number) => documentTypeAliasMap[id];

  getB2COrders = async (
    statusKeys?: string[],
    companyIds?: number[],
    warehouseIds?: number[],
    receiverIds?: number[],
    legalEntitiyIds?: number[],
    fromDeliveryDate?: string,
    toDeliveryDate?: string,
    withExecutionPercentage: boolean = false
  ) => {
    const axios = await AMSV3Api();
    return await axios.getB2cOrdersOrdersB2cOrdersGet(
      [],
      statusKeys,
      companyIds,
      warehouseIds,
      receiverIds,
      legalEntitiyIds,
      fromDeliveryDate,
      toDeliveryDate,
      1,
      10000
    );
  };

  getInternalOrders = async (
    statusKeys?: string[],
    companyIds?: number[],
    warehouseIds?: number[],
    receiverIds?: number[],
    fromDeliveryDate?: string,
    toDeliveryDate?: string
  ) => {
    const axios = await AMSV3Api();
    return await axios.getInternalTransfersOrdersInternalTransfersGet(
      [],
      statusKeys,
      companyIds,
      warehouseIds,
      receiverIds,
      fromDeliveryDate,
      toDeliveryDate,
      1,
      10000
    );
  };

  updateStockReceivingProcessVerifiedQuantities = async (
    deliveryRequestId: number,
    request: VerifyQuantitiesRequest
  ) => {
    const axios = await AMSV3Api();
    return await axios.updateStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPatch(
      deliveryRequestId,
      JSON.parse(JSON.stringify(request))
    );
  };

  updateStockReceivingProcessSetPrices = async (
    deliveryRequestId: number,
    request: SetPricesRequest
  ) => {
    const axios = await AMSV3Api();
    return await axios.updateStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPatch(
      deliveryRequestId,
      JSON.parse(JSON.stringify(request))
    );
  };

  getDelivery = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.readDeliveryDeliveriesDeliveryIdGet(id);
  };

  createDelivery = async (request: CreateDeliveryRequest) => {
    const axios = await AMSV3Api();
    return await axios.createDeliveryDeliveriesPost(request);
  };

  getDeliveryItemsByDeliveryId = async (deliveryId: number) => {
    const axios = await AMSV3Api();
    return await axios.readDeliveryItemsDeliveriesDeliveryIdItemsGet(
      deliveryId,
      1,
      MAX_NUMBER_OF_RESULTS
    );
  };

  getItemBatches = async (
    itemId: number,
    warehouses?: number[],
    skipDepleted: boolean = false,
    size?: number
  ) => {
    const axios = await AMSV3Api();
    return await axios.readAllItemBatchesItemsItemIdBatchesGet(
      itemId,
      warehouses,
      skipDepleted,
      1,
      size ?? MAX_NUMBER_OF_RESULTS
    );
  };

  deleteDelivery = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.deleteDeliveryDeliveriesIdDelete(id);
  };

  getDeliveryDocuments = async (deliveryId: number) => {
    const axios = await AMSV3Api();
    return await axios.readDeliveryDocumentsDeliveriesDeliveryIdDocumentsGet(
      deliveryId,
      1,
      MAX_NUMBER_OF_RESULTS
    );
  };

  createDeliveryDocument = async (request: CreateDeliveryDocumentRequest) => {
    const axios = await AMSV3Api();
    return await axios.createDeliveryDocumentDeliveryDocumentsPost(request);
  };

  updateDeliveryDocument = async (id: number, request: UpdateDeliveryDocumentRequest) => {
    const axios = await AMSV3Api();
    return await axios.updateDeliveryDocumentDeliveryDocumentsDeliveryDocumentIdPut(id, {
      ...request,
      invoiceTotal: request.invoiceTotal?.toString()
    });
  };

  deleteDeliveryDocument = async (id: number) => {
    const axios = await AMSV3Api();
    return await axios.deleteDeliveryDocumentsDeliveryDocumentIdDelete(id);
  };

  getDeliveryRemainingAmount = async (deliveryId: number) => {
    const axios = await AMSV3Api();
    return await axios.readDeliveryRemainingAmountDeliveriesDeliveryIdRemainingAmountGet(
      deliveryId
    );
  };
  getDeliveryDocumentTypeAlias = (type: DeliveryDocumentType) => deliveryDocumentTypeAliasMap[type];
}
