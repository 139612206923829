import { AMSCountrySelector, AMSDatePicker, AMSTextField } from '../../../../helpers/ui';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import {
  DeliveryRequestStatus,
  ItemListResponse,
  StockReceivingProcessItemResponse
} from '../../../../services';
import React, { CSSProperties, Dispatch, SetStateAction, useMemo } from 'react';
import { toDDMMYY, toDateString, toYYYYMMDD } from '../../../../helpers/date-helper';

import AMSLink from '../../../../helpers/ui/AMSLink/AMSLink';
import Country from '../../../../models/country';
import DeleteIcon from '@material-ui/icons/Delete';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import moment from 'moment';
import { useItems } from '../../../../helpers/hooks';

export interface StockReceivingProcessItem extends Partial<StockReceivingProcessItemResponse> {
  itemId: number;
  quantity: string;
}

interface CreateStockReceivingProcessTableComponentProps {
  supplierId: number;
  items: StockReceivingProcessItem[];
  setItems: Dispatch<SetStateAction<StockReceivingProcessItem[]>>;
  status: DeliveryRequestStatus;
}

const CreateStockReceivingProcessTableComponent = ({
  supplierId,
  items,
  setItems,
  status
}: CreateStockReceivingProcessTableComponentProps) => {
  const itemsData = useItems(items.map((item: StockReceivingProcessItem) => item.itemId));
  const itemsLookup: { [key: number]: ItemListResponse } = useMemo(
    () =>
      itemsData.items.reduce((res: any, item: any) => {
        res[item.id] = item;
        return res;
      }, {}),
    [itemsData]
  );
  const isCancelled = useMemo(() => status === DeliveryRequestStatus.CANCELLED, [status]);
  const cellStyle: CSSProperties = useMemo(
    () => ({
      width: '15%',
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 15,
      paddingRight: 15
    }),
    []
  );
  const wideCellStyle = useMemo(() => ({ ...cellStyle, width: '25%' }), [cellStyle]);

  const batchPlaceholder = useMemo(() => `${supplierId}_${toYYYYMMDD(new Date())}`, [supplierId]);

  const datePickerPlaceholder = useMemo(
    () => `${toDDMMYY(new Date(new Date().getTime() + 20 * 365 * 24 * 60 * 60 * 1000))}`,
    []
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={wideCellStyle}>Артикул</TableCell>
          <TableCell style={cellStyle}>Количество</TableCell>
          {!isCancelled && (
            <>
              <TableCell style={cellStyle} align="right">
                Партида
              </TableCell>
              <TableCell style={cellStyle} align="right">
                Срок на годност
              </TableCell>
              <TableCell style={cellStyle} align="right">
                Държава
              </TableCell>
              <TableCell style={cellStyle} align="right">
                Брой
              </TableCell>
              <TableCell style={cellStyle} align="right">
                Действия
              </TableCell>
            </>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item: StockReceivingProcessItem, index) => {
          const actualItem: ItemListResponse = itemsLookup[item.itemId];
          return (
            <TableRow key={index}>
              <TableCell style={wideCellStyle}>
                <AMSLink href={`/item?id=${item.itemId}`}>
                  {actualItem ? `${actualItem.artNo} ${actualItem.name}` : 'Зареждане ...'}
                </AMSLink>
              </TableCell>
              <TableCell style={cellStyle}>
                <Typography variant="body2">{item.quantity}</Typography>
              </TableCell>
              {!isCancelled && (
                <>
                  <TableCell style={cellStyle}>
                    {actualItem?.tracksBatches && (
                      <AMSTextField
                        label=""
                        value={item.batchNumber ?? ''}
                        onChange={(text?: string) => {
                          setItems(
                            items.reduce((res: any[], i: StockReceivingProcessItem) => {
                              if (item.id === i.id) {
                                i.batchNumber = text;
                              }
                              res.push(i);
                              return res;
                            }, [])
                          );
                        }}
                        placeholder={`по подр. ${batchPlaceholder}`}
                      />
                    )}
                  </TableCell>
                  <TableCell style={cellStyle}>
                    {actualItem?.tracksBatches && (
                      <AMSDatePicker
                        label=""
                        value={item.expirationDate ? new Date(item.expirationDate) : null}
                        onChange={(date: Date | null) => {
                          setItems(
                            items.reduce((res: any[], i: StockReceivingProcessItem) => {
                              if (item.id === i.id) {
                                i.expirationDate = date ? toDateString(date) : undefined;
                              }
                              res.push(i);
                              return res;
                            }, [])
                          );
                        }}
                        helperText={
                          item.expirationDate &&
                          moment.duration(moment(item.expirationDate).diff(moment())).asDays() <=
                            actualItem.minimumAllowedDaysToExpiration - 1
                            ? `Не преди ${moment()
                                .add(actualItem.minimumAllowedDaysToExpiration, 'days')
                                .format('DD/MM/YY')}`
                            : ``
                        }
                        error={
                          item.expirationDate
                            ? moment.duration(moment(item.expirationDate).diff(moment())).asDays() <
                              actualItem.minimumAllowedDaysToExpiration - 1
                            : undefined
                        }
                        placeholder={`по подр. ${datePickerPlaceholder}`}
                      />
                    )}
                  </TableCell>
                  <TableCell style={cellStyle}>
                    {actualItem?.tracksBatches && (
                      <AMSCountrySelector
                        onChange={(country: Country | null) => {
                          setItems(
                            items.reduce((res: any[], i: StockReceivingProcessItem) => {
                              if (item.id === i.id) {
                                i.countryOfOrigin = country?.alpha3;
                              }
                              res.push(i);
                              return res;
                            }, [])
                          );
                        }}
                        placeholder={`по подр. България`}
                      />
                    )}
                  </TableCell>
                  <TableCell style={cellStyle}>
                    <AMSTextField
                      label=""
                      value={item.documentQuantity ?? ''}
                      onChange={(text?: string) => {
                        setItems(
                          items.reduce((res: any[], i: StockReceivingProcessItem) => {
                            if (item.id === i.id) {
                              i.documentQuantity = text;
                            }
                            res.push(i);
                            return res;
                          }, [])
                        );
                      }}
                      placeholder={`по подр. ${item.quantity}`}
                    />
                  </TableCell>
                  <TableCell style={cellStyle}>
                    <Box style={{ display: 'flex', float: 'right' }}>
                      {actualItem?.tracksBatches && (
                        <IconButton
                          onClick={() => {
                            setItems(
                              items.reduce((res: any[], i: StockReceivingProcessItem) => {
                                res.push(i);
                                if (item.id === i.id) {
                                  res.push({
                                    ...i,
                                    id: Date.now(),
                                    batchNumber: undefined,
                                    expirationDate: toDateString(
                                      new Date(
                                        new Date().getTime() + 20 * 365 * 24 * 60 * 60 * 1000
                                      )
                                    ),
                                    countryOfOrigin: undefined,
                                    documentQuantity: undefined
                                  });
                                }
                                return res;
                              }, [])
                            );
                          }}
                        >
                          <PlaylistAddIcon />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => {
                          const tempItems = [...items];
                          tempItems.splice(index, 1);
                          setItems([...tempItems]);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default CreateStockReceivingProcessTableComponent;
